import React from 'react'
import GridItem from './GridItem/GridItem'
const AimForExcellence = () => {
  return (
    <div className="bg-white m-0 mb:m-20 lg:m-20">
        <br></br>
        <br></br>
        <br></br>
      
      <h1 className='text-center text-theme-green md:text-4xl text-3xl font-extrabold uppercase'>WE AIM FOR EXCELLENCE</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-4 md:px-20 mt-5 md:mt-10">
        <GridItem img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/values.jpg"} title="Mission" desc="Our mission is to provide top-notch quality garment products to our clients at
the best price in the market. By doing so, we want to establish ourselves as a
beacon of reliability to our clients."/>
        <GridItem img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/visionbg.jpg"} desc="Life Textiles Pvt. Ltd. aspires to be one of the leading players in the
ever-growing readymade garments (RMG) industry of Bangladesh. Our vision
is to continuously strive towards providing the best-in-class service to our
clients all over the world." 
title="Vision"/>
        <GridItem img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/progress.jpg"} desc="Life Textiles Pvt. Ltd. aspires to be one of the leading players in the
ever-growing readymade garments (RMG) industry of Bangladesh. Our vision
is to continuously strive towards providing the best-in-class service to our
clients all over the world." 
title="Values"/>
        <GridItem img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/mission.jpg"} desc="Life Textiles Pvt. Ltd. aspires to be one of the leading players in the
ever-growing readymade garments (RMG) industry of Bangladesh. Our vision
is to continuously strive towards providing the best-in-class service to our
clients all over the world." 
title="Progress"/>
      </div>
 <br></br>
 <br></br>
 <br></br>
    </div>
  )
}

export default AimForExcellence