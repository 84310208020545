const HomepageHero = () => {


    return(
        <div className="bg-white relative pt-10 lg:pt-24 mb-10">
  <div className="relative xl:container m-auto px-6 md:px-12 lg:px-6">
    <h1 className="sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl  ">
      Who we <br className="lg:block hidden" />{" "}
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
        Are?
      </span>
      
    </h1>
    <div className="lg:flex">
      <div className="relative mt-8 md:mt-16 space-y-8 sm:w-10/12 md:w-2/3 lg:ml-0 sm:mx-auto text-center lg:text-left lg:mr-auto lg:w-7/12">
        <p className="sm:text-lg text-gray-700  lg:w-4/5 text-justify">
        Life Textile believes in leadership through excellence. The Group has experienced phenomenal growth in apparel manufacturing, becoming the market leader due to the quality of its goods and services, its prime focus is on cost leadership and the efficiency of its human capital. Thus we are a distinct apparel group with strategic venture in furniture, restaurant, bio-degradable packaging and tech industry. We strive to achieve excellence through a culture of innovation and continuous improvement. As an organization, we believe in well thought-out consistent growth and a consciously conservative approach. We are committed to investing in the business with initiatives directed at long-term sustainability. The adherence to quality and timeliness in our exports have earned us a competitive advantage, having turned us into the industry leader of today.
        </p>
      
    
      
        <div className="scale-75 mb:scale-100 lg:scale-100 pt-2 flex gap-3 mb:gap-6 lg:gap-6 lg:gap-12 justify-center items-center lg:w-2/3">
        <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/organi.webp"}
            className="h-20 sm:h-10 w-auto mx-auto px-12 lg:h-20 grayscale hover:grayscale-0 transaction duration-100"
            alt=""
          />
        
            <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/bsci.webp"}
            className="h-20 sm:h-10 w-auto mx-auto px-12 lg:h-20 grayscale hover:grayscale-0 transaction duration-100"
            alt=""
          />
          <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/wrap.webp"}
            className="h-20 sm:h-10 w-auto px-4 lg:h-20 grayscale hover:grayscale-0 transaction duration-100"
            alt=""
          />
        </div>
        <div className="scale-75 mb:scale-100 lg:scale-100 pt-2 flex gap-3 mb:gap-6 lg:gap-6 lg:gap-12 justify-center items-center lg:w-2/3">
        <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/accord.webp"}
            className="h-20 sm:h-10 w-auto px-4 ml-20 mr-2 lg:h-20 grayscale hover:grayscale-0 transaction duration-100"
            alt=""
          />
        
        <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/oeko.webp"}
            className="h-20 sm:h-10 w-auto px-4 lg:h-20 grayscale hover:grayscale-0 transaction duration-100"
            alt=""
          />
          <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/recycle.webp"}
            className="h-24 sm:h-10 w-auto mx-auto pl-2 mr-20 md:mr-10 lg:mr-10 lg:h-28 grayscale hover:grayscale-0 transaction duration-100"
            alt=""
          />
      
        </div>
      </div>
      <div className="mt-12 md:mt-0 lg:absolute -right-10 lg:w-7/12">
        <div className="relative w-full">
          <div
            aria-hidden="true"
            className="absolute scale-75 md:scale-110 inset-0 m-auto w-full h-full md:w-96 md:h-96 rounded-full rotate-45 bg-gradient-to-r from-sky-500 to-cyan-300 blur-3xl"
          />
        <center>  <img
            src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/heromain.png"}
            className="relative w-4/5"
            alt="wath illustration"
            loading="lazy"
            width={300}
          /></center>
        </div>
      </div>
    </div>
  </div>
</div>

    )
}


export default HomepageHero