import { Modal } from 'antd'
import { useState } from 'react';
import mensproducts from './products/mens';
import womensproducts from './products/womens';
import kidsproducts from './products/kids';
import boysproducts from './products/boys';
import girlsproducts from './products/girls';






  export default function Products() {
    const [open, setOpen] = useState(false);
    const [womenopen, setWomenOpen] = useState(false);
    const [boyopen, setBoyOpen] = useState(false);
    const [girlopen, setGirlOpen] = useState(false);
    const [kidsopen, setKidOpen] = useState(false);

    return (
        <main className="mx-8 mb-32 mt-10 pt-24 md:pt-0 lg:pt-0">
            <center>
            <h1 className="sm:mx-auto  sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl  sm:text-5xl md:text-6xl lg:w-auto  xl:text-6xl  ">
      
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
        Products
      </span>
      
    </h1>
            </center>
            <br></br>
            <br></br>
        <div className="container mx-auto px-6">
          <div
            className="bg-cover hover:h-96 duration-300 h-64 rounded-md overflow-hidden bg-cover bg-center"
            style={{
              backgroundImage:
                `url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/catmale.jpg'})`,backgroundPosition:"center top",
            }}
           
          >
            <div className="bg-gray-900  bg-opacity-50 flex items-center h-full">
              <div className="px-10 max-w-xl">
                <h2 className="text-2xl text-white font-semibold">Men's Fashion</h2>
                <p className="mt-2 text-gray-100">
                  Life textile's collection on various male T-shirts, outfits, sports wear, under garments and others
                </p>
                <button onClick={() => setOpen(true)} className="flex items-center mt-4 px-3 py-2 bg-theme-green text-white text-sm uppercase font-medium rounded hover:bg-blue-500 focus:outline-none focus:bg-theme-green">
                  <span>View Now</span>
                  <svg
                    className="h-5 w-5 mx-2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="md:flex mt-8 md:-mx-4">
          <div
              className="bg-cover w-full h-64 md:mx-4 rounded-md hover:h-96 duration-300  overflow-hidden bg-cover bg-center md:w-1/2"
              style={{
                backgroundImage:
                  `url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/catfemale.jpg'})`,backgroundPosition:"center top",
              }}
             
            >
              <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
                <div className="px-10 max-w-xl">
                  <h2 className="text-2xl text-white font-semibold">Ladie's Fashion</h2>
                  <p className="mt-2 text-gray-100">
                    Browse super comfort female outfits from Life Fashion   
                  </p>
                  <button   onClick={() => setWomenOpen(true)}className="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
                    <span>View Now</span>
                    <svg
                      className="h-5 w-5 mx-2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            
            <div
              className="bg-cover w-full h-64 hover:h-96 duration-300 mt-8 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:mt-0 md:w-1/2"
              style={{
                backgroundImage:
                  `url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/catkids.jpg'})`,backgroundPosition:"center center",
              }}
            >
              <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
                <div className="px-10 max-w-xl">
                  <h2 className="text-2xl text-white font-semibold">Kids Fashion</h2>
                  <p className="mt-2 text-gray-100">
                    Stylish wearables for kids.
                  </p>
                  <button  onClick={() => setKidOpen(true)} className="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
                    <span>View Now</span>
                    <svg
                      className="h-5 w-5 mx-2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex mt-8 md:-mx-4">
          <div
              className="bg-cover w-full h-64 md:mx-4 rounded-md hover:h-96 duration-300  overflow-hidden bg-cover bg-center md:w-1/2"
              style={{
                backgroundImage:
                  `url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/catboy.jpg'})`,backgroundPosition:"center top",
              }}
             
            >
              <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
                <div className="px-10 max-w-xl">
                  <h2 className="text-2xl text-white font-semibold">Boy's Fashion</h2>
                  <p className="mt-2 text-gray-100">
                    Browse super comfort female outfits from Life Fashion   
                  </p>
                  <button   onClick={() => setBoyOpen(true)}className="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
                    <span>View Now</span>
                    <svg
                      className="h-5 w-5 mx-2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            
            <div
              className="bg-cover w-full h-64 hover:h-96 duration-300 mt-8 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:mt-0 md:w-1/2"
              style={{
                backgroundImage:
                  `url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/catgirl.jpg'})`,backgroundPosition:"center center",
              }}
            >
              <div className="bg-gray-900 bg-opacity-50 flex items-center h-full">
                <div className="px-10 max-w-xl">
                  <h2 className="text-2xl text-white font-semibold">Girl's Fashion</h2>
                  <p className="mt-2 text-gray-100">
                    Stylish wearables for kids.
                  </p>
                  <button  onClick={() => setGirlOpen(true)} className="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
                    <span>View Now</span>
                    <svg
                      className="h-5 w-5 mx-2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
         
        </div>

        <Modal
        title="Men's"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={window.innerWidth}
      >
      <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {mensproducts.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
            </a>
          ))}
        </div>
      </div>
    </div>
      </Modal>



      <Modal
        title="Boy's"
        centered
        open={boyopen}
        onOk={() => setBoyOpen(false)}
        onCancel={() => setBoyOpen(false)}
        width={window.innerWidth}
      >
      <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {boysproducts.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
            </a>
          ))}
        </div>
      </div>
    </div>
      </Modal>

      <Modal
        title="Girl's"
        centered
        open={girlopen}
        onOk={() => setGirlOpen(false)}
        onCancel={() => setGirlOpen(false)}
        width={window.innerWidth}
      >
      <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {girlsproducts.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
            </a>
          ))}
        </div>
      </div>
    </div>
      </Modal>






      <Modal
        title="Ladie's"
        centered
        open={womenopen}
        onOk={() => setWomenOpen(false)}
        onCancel={() => setWomenOpen(false)}
        width={window.innerWidth}
      >
      <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        

        <div className="grid grid-cols-1 gap-x-6 gap-y-14 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {womensproducts.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
              <center>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
              </center>
            </a>
          ))}
        </div>
      </div>
    </div>
      </Modal>

      <Modal
        title="Kid's"
        centered
        open={kidsopen}
        onOk={() => setKidOpen(false)}
        onCancel={() => setKidOpen(false)}
        width={window.innerWidth}
      >
      <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        

        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {kidsproducts.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center group-hover:opacity-75"
                />
              </div>
<center>
<h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>

</center>

            </a>
          ))}
        </div>
      </div>
    </div>
      </Modal>
      </main>
      
    )
  }