import React from "react";
import "./gallery.scss"

const slides = [
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/1.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/2.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/3.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/4.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/5.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/6.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/7.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/8.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/9.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/10.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/11.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/12.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/13.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/14.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/15.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/16.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/17.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/18.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/19.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/20.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/21.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/22.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/23.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/24.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/25.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/26.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/27.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/28.jpg',
  'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/29.jpg',

];

function useTilt(active) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current || !active) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = e.clientX;
      state.mouseY = e.clientY;
      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    el.addEventListener("mousemove", handleMouseMove);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
    };
  }, [active]);

  return ref;
}

const initialState = {
  slideIndex: 0
};

const slidesReducer = (state, event) => {
  if (event.type === "NEXT") {
    return {
      ...state,
      slideIndex: (state.slideIndex + 1) % slides.length
    };
  }
  if (event.type === "PREV") {
    return {
      ...state,
      slideIndex:
        state.slideIndex === 0 ? slides.length - 1 : state.slideIndex - 1
    };
  }
};

function Slide({ slide, offset }) {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div

      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1
      }}
    >
      <div
        className="slideBackground"
        style={{
          backgroundImage: `url('${slide}')`
        }}
      />
      <div
        className="slideContent bg-cover"
        style={{
          backgroundImage: `url('${slide}')`
        }}
      >
       
      </div>
    </div>
  );
}

export default function Gallery() {
  const [state, dispatch] = React.useReducer(slidesReducer, initialState);
  
  return (
    <div className="slides  scale-50 my-10 pt-24 md:pt-0 lg:pt-0">
      <button className="text-theme-green mt-12 " onClick={() => dispatch({ type: "PREV" })}>‹</button>

      {[...slides, ...slides, ...slides].map((slide, i) => {
        let offset = slides.length + (state.slideIndex - i);
        return <Slide slide={slide} offset={offset} key={i} />;
      })}
      <button className="text-theme-green mt-12 " onClick={() => dispatch({ type: "NEXT" })}>›</button>
    </div>
  );
}

