import { Divider } from "antd"

const ComingSoon = () => {

    return(
        <div className="pt-12 md:pt-0 lg:pt-0">
  <section className="bg-white ">
    <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
      <div className="mr-auto place-self-center lg:col-span-7">
      <h1 className="sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl  ">
      
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
       LIFE TEXTILE PVT. LTD.
      </span>
      
    </h1>
        
       
      </div>
      <div className="mt-10 lg:mt-0 lg:col-span-5 lg:flex">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/heromain.png"}
          alt="hero image"
        />
      </div>
    </div>
    <div>
        <h1 className="text-xl">Coming Soon</h1>
    </div>
    <Divider></Divider>
    <br></br>
    <br></br>
    <br></br>
  </section>

</div>

    )
}


export default ComingSoon