import { Carousel } from "react-carousel3"
import "./homecar.scss"
import bannerimg01 from "../assets/bannerimg01.png"
import bannerimg02 from "../assets/bannerimg02.png"
import bannerimg03 from "../assets/bannerimg03.png"
import bannerimg04 from "../assets/bannerimg04.png"
import bannerimg05 from "../assets/bannerimg05.png"
import bannerimg06 from "../assets/bannerimg06.png"
import bannerimg07 from "../assets/bannerimg07.png"
import bannerimg08 from "../assets/bannerimg08.png"
import bannerimg09 from "../assets/bannerimg09.png"
import React from 'react';
import { Image } from "antd"


const style = {
  width: 350,
  height: 350,
};

const HomeMainCarousel = () => (

  <center>
     <div style={{
      display:"flex"
 
    }}>
  
 <div style={{opacity:1,}}>
 <Carousel 
    // yOrigin={50} yRadius={50}
   height={window.innerHeight / 1.5} 
   width={window.innerWidth /2} 

    autoPlay={true}>
     <div key={1} style={style}>
       <Image preview={false} alt="" src={bannerimg01} />
     </div>
   
     <div key={4} style={style}>
       <Image preview={false} alt="" src={bannerimg04} />
     </div>
     <div key={5} style={style}>
       <Image preview={false} alt="" src={bannerimg05} />
     </div>
   
     <div key={6} style={style}>
       <Image preview={false} alt="" src={bannerimg07} />
     </div>
     <div key={7} style={style}>
       <Image preview={false} alt="" src={bannerimg08} />
     </div>
     <div key={8} style={style}>
       <Image preview={false} alt="" src={bannerimg09} />
     </div>
   </Carousel>
 </div>
   
     </div>
  </center>
);

export default HomeMainCarousel