import "./clientSlider.css"

const ClientSlider = () => {


    return(
        <div className="slider">
            <div className="slide-track align-middle">
            <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Kiabi.jpg"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Cotton On.png"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/FredMeyer.png"}  width="150" alt="" />
                </div>
          
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Mango.png"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={ "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Matalan.png"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Primark.jpeg"}  width="150" alt="" />
                </div>

                <div className="slide mx-24 ">
                    <img className="justify-center mt-10 pl-4" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/umbro.png"}  width="100" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Cotton On.png"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Kiabi.jpg"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/FredMeyer.png"}  width="150" alt="" />
                </div>
               
                <div className="slide mx-24 ">
                    <img className="justify-center" src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Mango.png"}  width="150" alt="" />
                </div>
                <div className="slide mx-24 ">
                    <img className="justify-center" src={ "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/clients/Matalan.png"}  width="150" alt="" />
                </div>
              
              
                
            </div>

        </div>
        )
}


export default ClientSlider