import {Image} from 'antd'
import React from 'react'

const RecogAdapter = ({title,img}) => {
  return (
    <div className='border-2 border-dashed border-sky-200 rounded-xl p-3 md:p-7 flex items-center justify-center flex-col m-2'>
        <Image src={img} height={100} preview={false} width={100} alt={title}/>
        <p className='text-center text-xl text-gray-700 uppercase mt-3'>{title}</p>
    </div>
  )
}

export default RecogAdapter