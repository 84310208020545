
const About = () => {

    return(
        <div className="pt-12 md:pt-0 lg:pt-0">
  <section className="bg-white ">
    <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
      <div className="mr-auto place-self-center lg:col-span-7">
      <h1 className="sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl  ">
      
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
       LIFE TEXTILE PVT. LTD.
      </span>
      
    </h1>
        
       
      </div>
      <div className="mt-10 lg:mt-0 lg:col-span-5 lg:flex">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/heromain.png"}
          alt="hero image"
        />
      </div>
    </div>
  </section>
  <section className="bg-white ">
    <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16 scale-75">
      <div className="grid grid-cols-3 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 ">
        <a href="#" className="flex items-center lg:justify-center">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/accord.webp"}
          alt="hero image"
        />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/wrap.webp"}
          alt="hero image"
        />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/bsci.webp"}
          alt="hero image"
        />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/oeko.webp"}
          alt="hero image"
        />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/recycle.webp"}
          alt="hero image"
        />
        </a>
        <a href="#" className="flex items-center lg:justify-center">
        <img
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/organi.webp"}
          alt="hero image"
        />
        </a>
      </div>
    </div>
  </section>
  <section className="bg-gray-50 ">
    <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
      <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <div className="text-gray-500 sm:text-lg ">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">
            Mission
          </h2>
          <p className="mb-8 font-light lg:text-xl text-justify">
          Our mission is to provide top-notch quality garment products to our clients at 
the best price in the market. By doing so, we want to establish ourselves as a 
beacon of reliability to our clients. We believe in the philosophy of growing 
our business while maintaining a sustainable society for the future generation. 
In Life Textiles Pvt. Ltd., we consider our human resources to be our most 
valued asset and we do not leave any stones unturned in preserving their 
rights and privileges. 
          </p>
     
        </div>
        <img
          className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/aboutmission.jpg"}
          alt="dashboard feature image"
        />
      </div>
      <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
        <img
          className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/aboutvision.jpg"}
          alt="feature image 2"
        />
        <div className="text-gray-500 sm:text-lg ">
          <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">
         Vision
          </h2>
          <p className="mb-8 font-light lg:text-xl text-justify">
          Life Textiles Pvt. Ltd. aspires to be one of the leading players in the 
ever-growing readymade garments (RMG) industry of Bangladesh. Our vision 
is to continuously strive towards providing the best-in-class service to our 
clients all over the world. We want to give it back to the society through our 
corporate social responsibilities while complying with all the statutory 
regulations. 
          </p>
       
        </div>
      </div>
    </div>
  </section>
  <section className="bg-white ">
    <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
      <div className="col-span-2 mb-8">
        <p className="text-lg font-medium text-purple-600 ">
          Trusted Worldwide
        </p>
        <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl ">
          Trusted by leading fashion companies around the world
        </h2>
        <p className="font-light text-gray-500 sm:text-xl ">
          Our partners are top notch fashion providers such as Tom Taylor, Cotton on, LIDL, Mango, Matalan, Next, Umbro, Celio and others.
        </p>
        <div className="pt-6 mt-6 space-y-4 border-t border-gray-200 ">
       
        </div>
      </div>
      <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
        <div>
          <svg
            className="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
              clipRule="evenodd"
            />
          </svg>
          <h3 className="mb-2 text-2xl font-bold ">
          10 Years
          </h3>
          <p className="font-light text-gray-500 ">
          EXPERIENCE
          </p>
        </div>
        <div>
          <svg
            className="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
          </svg>
          <h3 className="mb-2 text-2xl font-bold ">
          
24/7
          </h3>
          <p className="font-light text-gray-500 ">
          SUPPORT
          </p>
        </div>
        <div>
          <svg
            className="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
              clipRule="evenodd"
            />
          </svg>
          <h3 className="mb-2 text-2xl font-bold ">
           8M
          </h3>
          <p className="font-light text-gray-500 ">
          UNIT/MONTH
          </p>
        </div>
        <div>
          <svg
            className="w-10 h-10 mb-2 text-purple-600 md:w-12 md:h-12 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
          </svg>
          <h3 className="mb-2 text-2xl font-bold ">
          
175
          </h3>
          <p className="font-light text-gray-500 ">
          LINES
          </p>
        </div>
      </div>
    </div>
  </section>
  <section className="bg-gray-50 ">
    <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-24 lg:px-6">
      <figure className="max-w-screen-md mx-auto">
        <svg
          className="h-12 mx-auto mb-3 text-gray-400 "
          viewBox="0 0 24 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
            fill="currentColor"
          />
        </svg>
        <blockquote>
          <p className="text-xl font-medium text-gray-900 md:text-2xl ">
            "Life Textiles Pvt Ltd. never compromises on the quality of our products. Great 
attention to detail is given to each and every product we produce on our 
factory so that our customers can always rely on the delivered products."
          </p>
        </blockquote>
        <figcaption className="flex items-center justify-center mt-6 space-x-3">
          
          <div className="flex items-center divide-x-2 divide-gray-500">
            <div className="pr-3 font-medium text-gray-900 ">
Abdullah Al Noman            </div>
            <div className="pl-3 text-sm font-light text-gray-500 ">
              Director at Life Textile Pvt. Ltd.
            </div>
          </div>
        </figcaption>
      </figure>
    </div>
  </section>

  <section className="bg-gray-50 ">
    <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
      <div className="max-w-screen-sm mx-auto text-center">
        <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900">
        Get in touch with us
        </h2>
        <p className="mb-6 font-light text-gray-500  md:text-lg">
          We will be pleased to help you sort your queries.
        </p>
        <a
          href="./contact"
          className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
        >
          Contact
        </a>
      </div>
    </div>
  </section>
</div>

    )
}


export default About