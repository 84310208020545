
const mensproducts = [
    {
      id: 1,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Boxer%2C+Fabrication-87%25+Cotton%2C+8%25+Elastane%2C+5%25+Viscose.jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Boxer%2C+Fabrication-92%25+Cotton%2C+8%25+Elastane_1.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 3,
      name: 'Focus Paper Refill',
      href: '#',
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Boxer%2C+Fabrication-95%25+Cotton+5%25+Elastane.jpg',
      imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
    },
    {
      id: 4,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Boxer%2C+Fabrication-95%25+Cotton%2C+5%25+Elastane.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 5,
      name: 'Machined Mechanical Pencil',
      href: '#',
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Long+Sleeve+T-Shirt%2C+Fabrication-63%25+Cotton+31%25+Polyster+6%25+Elastine.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 6,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Polo+T-Shirt%2C+Fabrication-100%25+Cotton+(PK).jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 7,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Polo+T-Shirt%2C+Fabrication-100%25+Cotton.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 8,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Polo+T-Shirt%2C+Fabrication-92%25+Polyster+8%25+Elastine.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 9,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Short%2C+Fabrication-100%25+Cotton+(AOP).jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 10,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+T-Shirt%2C+Fabrication-100%25+Cotton.jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 11,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Mens/Mens+Top+%26+Bottom+Set%2C+Fabrication-100%25+Cotton+(Top)+%26+61%25+Cotton%2C+39%25+Ployester+(Bottom).jpg',
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
   
  ]

  export default mensproducts