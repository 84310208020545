import { useEffect, useState } from "react"

import { Image, Drawer, Divider, List, Button } from "antd"

const Navbar = () => {
    
    const [visable, setVisable] = useState(true)
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
   


    return(
        <nav style={{position:"fixed", zIndex:"9999"}}  className={`transition ease-linear duration-800  top-0 flex w-full fixed md:relative lg:relative z-50  bg-white w-full flex  justify-between items-center mx-auto fixed px-2 md:px-32  h-24 ${
            visable ? '' : 'opacity-0 invisible'
          }`}>
  {/* logo */}
  
  <div className="inline-flex fixedd">
    
    <Image style={{cursor:"pointer"}} onClick={()=>{
      window.location = "./home"
    }} src={'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/logoBig.svg'} width={window.innerHeight > window.innerWidth ? 200 : 350} preview={false}/>
  
  </div>
  {/* end logo */}
  {/* search bar */}
  <div className="hidden sm:block flex-shrink flex-grow-0 items-center justify-center pr-64">

   <div className="inline-block">
      <div className="inline-flex items-center max-w-full">
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./home" className="p-2 m-2 ">Home</a></div>
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./about" className="p-2 m-2 ">About</a></div>
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./vr%20room" className="p-2 m-2 ">VR Room</a></div>
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./gallery" className="p-2 m-2 ">Gallery</a></div>
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./products" className="p-2 m-2 ">Products</a></div>
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./recognition" className="p-2 m-2 ">Recognition</a></div>
     <div className="m-1 font-bold text-meduim  hover:text-amber-500 delay-100 transition duration-150 text-gray-500"><a href="./contact" className="p-2 m-2 ">Contact</a></div>
      </div>
    </div>
  </div>
  {/* end search bar */}
  {/* login */}
  <div className="flex-initial">
    <div className="flex justify-end items-center relative">
  
    
      <div className="block">
        <div className="inline relative">
          <button
          onClick={showDrawer}
            type="button"
            style={{backgroundColor:"#e21722"}}
            className="inline-flex items-center  relative px-2 border rounded-full "
          >
            <div className="pl-1">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={{
                  display: "block",
                  fill: "none",
                  height: 24,
                  width: 24,
                  stroke: "white",
                  strokeWidth: 3,
                  overflow: "visible"
                }}
              >
                <g fill="none" fillRule="nonzero">
                  <path d="m2 16h28" />
                  <path d="m2 24h28" />
                  <path d="m2 8h28" />
                </g>
              </svg>
            </div>
            <div className="block flex-grow-0 flex-shrink-0 h-10 w-12 pl-5">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style={{
                  display: "block",
                  height: "100%",
                  width: "100%",
                  fill: "white"
                }}
              >
                <path d="m16 .7c-8.437 0-15.3 6.863-15.3 15.3s6.863 15.3 15.3 15.3 15.3-6.863 15.3-15.3-6.863-15.3-15.3-15.3zm0 28c-4.021 0-7.605-1.884-9.933-4.81a12.425 12.425 0 0 1 6.451-4.4 6.507 6.507 0 0 1 -3.018-5.49c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5a6.513 6.513 0 0 1 -3.019 5.491 12.42 12.42 0 0 1 6.452 4.4c-2.328 2.925-5.912 4.809-9.933 4.809z" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <Drawer  placement="right" width={500} closable={false} onClose={onClose} open={open}>
      {window.innerHeight > window.innerWidth ?   <>
     <p style={{justifyContent:"left", textAlign:"right", width:"100%", justifyItems:"right", display:"flex"}}>
        <img src={'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/logoBig.svg'} width={150} style={{ justifyContent:"left"}} />
        <img src={'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/close.png'} width={50} className="ml-32" onClick={onClose} style={{borderRadius:"100%", backgroundColor:"#0B9B4D29", padding:"10px", justifyContent:"right", cursor:"pointer"}} />
       </p>
       <Divider></Divider>
       <List
      size="large"
      
      dataSource={[
        'Home',
        'About',
        'VR Room',
        'Gallery',
        'Products',
        'Recognition',
        'Contact',
      ]}
      renderItem={(item) => <List.Item className="font-semibold" style={{padding:"8px"}} onClick={()=>{
        window.location = `./${item.toLowerCase()}`
      }}>{item}</List.Item>}
    />
      </> :  <>
     <p style={{justifyContent:"right", textAlign:"right", width:"100%", justifyItems:"right"}}>
        <img src={'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/close.png'} width={50} onClick={onClose} style={{borderRadius:"100%", backgroundColor:"#0B9B4D29", padding:"10px", justifyContent:"end", cursor:"pointer"}} />
       </p>
       <br></br>
       <p className="text-4xl uppercase m-10 font-bold p-4">
       Life Textiles Pvt Ltd. is a 100%  <span className="text-theme-green">export-oriented</span> Garments 
Manufacturing Company established in August 1993.
       </p>
       <p className="text-sm m-10 font-light p-4">We 
provide the best quality garment products while offering the 
best rate in the market. </p>
    <br></br>
    <br></br>
       <p className="text-2xl mx-0  md:mx-10 font-bold px-0 md:px-4">
       <span className="text-theme-green">+880 1817-751943</span>
       </p>
       <p className="text-2xl mx-0 md:mx-10 font-bold px-0 md:px-4">
      INFO@LIFETEXTILEBD.COM
       </p>
       
       
       <center>
        <Divider></Divider>
       <Button onClick={()=>{
        window.location = "https://lifetextilebd.com/admin"
       }} style={{backgroundColor:"red", color:"white",}} size="large">
       Admin Login
       </Button>
       </center>
       
       </>}
      </Drawer>
  {/* end login */}
</nav>

    )
}

export default Navbar