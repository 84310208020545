import {Image} from 'antd'
import React from 'react'
import styles from './GridItem.module.css'
const GridItem = ({title,desc,img}) => {
  return (
    <div className={`${"border-0  relative border-blue-500 rounded-sm hover:scale-105 m-5 transition-all ease-in-out duration-300"} ${styles.mainContainer}`}>
          <Image src={img} width={300} height={500} alt={title}/>
          <div className="bg-red absolute transition-all ease-in-out duration-500 hover:bg-theme-green hover:bg-opacity-70 hover:backdrop-blur-md top-0 left-0 w-full h-full bottom-0 right-0 bg-opacity-25 flex items-center justify-center flex-col">
            <div className={styles.subContainer}>
              <p className={styles.top}>Our</p>
              <p className='text-white uppercase text-4xl'>{title}</p>
              <p className={styles.desc}>{desc}</p>
             
            </div>
           
          </div>
    </div>
  )
}

export default GridItem