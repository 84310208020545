import { Divider } from "antd"

const Contact = () => {


    return(
        <section className="text-gray-600 body-font relative">
  <div className="absolute inset-0 bg-gray-300">
    <iframe
      width="100%"
      height="100%"
      title="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d645.4125717336927!2d90.39554174503455!3d23.78284165999088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c76f6fb8ca67%3A0xf5eba7d6aaea2b97!2sMuazuddin%20Group%2C%20Head%20office!5e0!3m2!1sen!2sbd!4v1691228770817!5m2!1sen!2sbd"
      style={{}}
    />
  </div>
  <div className="container px-5 py-24 mx-auto flex">
    <div className="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 justify-start item-start text-start relative z-10 shadow-md">
    <p className="text-2xl font-bold">
        Contacts
     </p>
     <Divider></Divider>
   
    
      <span className="font-bold">Address:</span>  House # 39 (3rd Floor, C-3, D-4),
Road# 20, New D.O.H.S Mohakhali. 
Dhaka-1206, Bangladesh.
     
     <p>
     <span className="font-bold">Phone:</span> +880 1817-751943
     </p>
     <p>
     <span className="font-bold">Email:</span> info@lifetextilebd.com
     </p>
    <br></br>
   
    
    <span className="font-bold">Factory Address:</span>  69-71, BSCIC, Konabari, Gazipur, 
Bangladesh.
     
   
    <br></br>
    <br></br>
    <p className="text-2xl font-bold">
        Work hours
     </p>
     <Divider></Divider>
     <p>Saturday - Thursday</p>
     <p>9:00AM - 7:00PM</p>
    </div>
  </div>
</section>

    )
}

export default Contact