import React from 'react'
import { getAllMessages, logIn } from '../components/firebase'
import { root } from '..'
import { Space, Table, Tag, message } from 'antd';
import { Button, Checkbox, Form, Input } from 'antd';



const columns = [
    {
      title: 'Name',
      dataIndex: 'from_name',
      key: 'from_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'from_email',
      key: 'from_email',
    },
    {
      title: 'Phone',
      dataIndex: 'from_phone',
      key: 'from_phone',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
   
  ];

  const onFinish = (values) => {
    
    logIn(values.email, values.password)


  };
  const onFinishFailed = () => {
    message.error("Login Failed")
  };

const Admin = () => {
    getAllMessages().then((e)=>{
        root.render(

            <div className='pt-32 px-32 md:pt-0 lg:pt-0'>
       <h1 className="sm:mx-auto  sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl  sm:text-5xl md:text-6xl lg:w-auto  xl:text-6xl  ">
    
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
        Admin
      </span>
      
    </h1>
    <br></br>
    <br></br>
    <Table columns={columns} dataSource={e} />
   </div>
        )
    }).catch((e)=>{
        root.render(

            <div className='pt-32 px-32 md:pt-0 lg:pt-0'>
       <h1 className="sm:mx-auto  sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl  sm:text-5xl md:text-6xl lg:w-auto  xl:text-6xl  ">
    
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
        Un-authorised
      </span>
      
    </h1>
    <br></br>
    <br></br>
    <Form
    name="login"
   
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          required: true,
          message: 'Please input your username!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>



    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" style={{backgroundColor:"green"}} htmlType="submit">
        Login
      </Button>
    </Form.Item>
  </Form>
   </div>
        )
    })
 
}

export default Admin