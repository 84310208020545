
const womensproducts = [
  
    {
      id: 1,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/scx1.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 2,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/scx2.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 3,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/scx3.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 4,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/scx4.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 5,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/scx5.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 61,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/scx6.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 7,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Brief%2C+Febrication-95%25+Cotton%2C+5%25+Lycra.jpg",
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 8,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Long+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton.jpg",
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 9,
      name: 'Focus Paper Refill',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Long+Sleeve+T-Shirt%2C+Fabrication-95%25+Cotton+5%25+Viscose.jpg",
      imageAlt: 'Person using a pen to cross a task off a productivity paper card.',
    },
    {
      id: 10,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Night+Wear%2C+Fabrication-100%25+Cotton%2C+1X1+RIB.jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 11,
      name: 'Machined Mechanical Pencil',
      href: '#',
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Penty%2C+Febrication-100%25+Cotton+(AOP).jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 12,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Penty%2C+Febrication-100%25+Cotton.jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 13,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton+(AOP).jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 14,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Short+Sleeve+T-Shirt%2C+Fabrication-92%25+Polyster%2C+5%25+Elastine.jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 15,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Short%2C+Febrication-100%25+Cotton+(Yarn+Dyed).jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 16,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Sports+Bra%2C+Febrication-100%25+Cotton+(Yern+Dyed).jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 17,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+Sports+Bra%2C+Febrication-100%25+Cotton-1.jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
    {
      id: 18,
      name: 'Machined Mechanical Pencil',
      href: '#',
   
      imageSrc: "https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Ledies/Ladies+T-Shirt+Fabrication-95%25+Cotton%2C+5%25+Spandex.jpg",
      imageAlt: 'Hand holding black machined steel mechanical pencil with brass tip and top.',
    },
   
  ]

  export default womensproducts