

const Footer = () => {


    return(
        <>
 <footer className="bg-gray-900 p-10">
  <div className="container px-6 py-12 mx-auto">
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
      <div className="sm:col-span-2">
      <a href="./home">
        <img
          className="w-2/3 "
          src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Logowhite.svg"}
          alt=""
        />
      </a>
      </div>
      <div>
        <p className="font-semibold text-white">
          Quick Link
        </p>
        <div className="flex flex-col items-start mt-5 space-y-2">
          <a
            href="./"
            className=" transition-colors duration-300 text-gray-300 hover:text-blue-400 hover:underline hover:text-blue-500"
          >
            Home
          </a>
          <a
            href="./about"
            className=" transition-colors duration-300 text-gray-300 hover:text-blue-400 hover:underline hover:text-blue-500"
          >
            About
          </a>
          <a
            href="./contact"
            className=" transition-colors duration-300 text-gray-300 hover:text-blue-400 hover:underline hover:text-blue-500"
          >
            Contact
          </a>
        </div>
      </div>
      <div>
        <p className="font-semibold text-gray-800 text-white">
          Showcase
        </p>
        <div className="flex flex-col items-start mt-5 space-y-2">
          <a
            href="./products"
            className=" transition-colors duration-300 text-gray-300 hover:text-blue-400 hover:underline hover:text-blue-500"
          >
            Products
          </a>
          <a
            href="./gallery"
            className=" transition-colors duration-300 text-gray-300 hover:text-blue-400 hover:underline hover:text-blue-500"
          >
            Gallery
          </a>
          <a
            href="./recognition"
            className=" transition-colors duration-300 text-gray-300 hover:text-blue-400 hover:underline hover:text-blue-500"
          >
            Recognition
          </a>
        </div>
      </div>
    </div>
    <hr className="my-6 border-gray-200 md:my-8 border-gray-700" />
  <center className="text-gray-500">
    <p>&copy;  Life Textile - 2023 (Designed and Developed by Intredia LLC)</p>
  </center>
  </div>
</footer>

  {/*

    Add to your stylesheet

.bg-layers {
    -webkit-animation: filter-animation 4s infinite;
    animation: filter-animation 4s infinite;
}

.bg-scale {
    -webkit-animation: filter-animation 8s infinite;
    animation: filter-scale 8s infinite;
}
  
@-webkit-keyframes filter-animation {
    0% {
-webkit-filter: hue-rotate(0deg);
    }
    50% {
-webkit-filter: hue-rotate(45deg);
    }
    
    100% {
-webkit-filter: hue-rotate(0deg);
    }
}
  
@keyframes filter-animation {
    0% {
filter: hue-rotate(0deg);
    }
    50% {
filter: hue-rotate(45deg);
    }
    100% {
filter: hue-rotate(0deg);
    }
}

@keyframes filter-scale {
    0% {
transform:scale(1);
    }
    50% {
transform:scale(1.4);
    }
    100% {
  transform:scale(.8);
    }
}
*/}
</>

    )
}

export default Footer