import Link from 'antd/es/typography/Link'
import React from 'react'

const SolutionGridContainer = ({img,title,text,btn,subtitle,link}) => {
  return (
    <div className="p-3 md:p-5">
    <div className='bg-no-repeat bg-cover bg-center h-96 w-full relative' style={{backgroundImage:`url('${img}')`}}>
        <div className="absolute top-0 left-0 right-0 bottom-0 h-full w-full bg-black bg-opacity-50 flex flex-col justify-center px-6">
          <h1 className='text-gray-100  uppercase font-semibold text-3xl'>{title}</h1>
          <p className='text-amber-500 py-2 text-sm'>{subtitle}</p>
          <p className='text-md text-white text-justify'>{text}</p>
          <Link href={link}>
          <button className="text-gray-300 px-5 py-2 mt-10 transition-all ease-in-out duration-300 hover:bg-opacity-80 bg-theme-green w-32">{btn}</button>
          </Link>
        </div>
    </div>
    </div>
  )
}

export default SolutionGridContainer