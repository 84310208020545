import { Image } from "antd"
import "./gallerySlider.css"


const GallerySlider = () => {


    return(
        <>
        <article className="wrapper">
          <div className="marquee">
            <div className="marquee__group">
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/1.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#mcdonalds" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/2.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#jordan" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/3.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#aws" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/4.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#spotify" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/5.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#burger-king" />
              </svg>
              <svg style={{background:`url(${ 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/6.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#honda" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/7.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#notion" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/8.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#hulu" />
              </svg>
            </div>
            <div aria-hidden="true" className="marquee__group">
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/9.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#mcdonalds" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/10.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#jordan" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/11.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#aws" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/12.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#spotify" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/13.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#burger-king" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/14.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#honda" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/15.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#notion" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/16.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#hulu" />
              </svg>
            </div>
          </div>
          <div className="marquee marquee--reverse">
            <div className="marquee__group">
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/17.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#hulu" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/18.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#notion" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/19.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#honda" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/20.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#burger-king" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/21.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#spotify" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/22.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#aws" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/23.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#jordan" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/24.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#mcdonalds" />
              </svg>
            </div>
            <div aria-hidden="true" className="marquee__group">
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/25.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#hulu" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/26.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#notion" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/28.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#honda" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/27.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#burger-king" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/29.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#spotify" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/28.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#aws" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/27.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#jordan" />
              </svg>
              <svg style={{background:`url(${'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/gallery/26.jpg'})`, backgroundSize:"100%"}}>
                <use xlinkHref="#mcdonalds" />
              </svg>
            </div>
          </div>
        </article>
     
      </>
      
        )
}


export default GallerySlider