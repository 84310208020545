// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import { message, } from "antd";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB0zQ16hHl0RQnj0lrjjzozlMzPrEBJqf8",
    authDomain: "life-textile-pte.firebaseapp.com",
    projectId: "life-textile-pte",
    storageBucket: "life-textile-pte.appspot.com",
    messagingSenderId: "348721865742",
    appId: "1:348721865742:web:5d4e1023dbb84cd8e6085f",
    measurementId: "G-FY49R35LGP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);




function getFormattedDate() {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
  
    // Pad the day and minutes with leading zeros if needed
    const formattedDay = (day < 10) ? `0${day}` : day;
    const formattedMinutes = (minutes < 10) ? `0${minutes}` : minutes;
  
    // Combine all the components in the desired format
    const formattedDate = `${formattedDay}-${month}-${year} (${hours}:${formattedMinutes})`;
  
    return formattedDate;
  }
  



export const pMessToFire = (messageS) => {

    let time = getFormattedDate()

    setDoc(doc(db, "messages", `${time}`), 
    messageS
    ).then((e)=>{
        message.success("Message Sent!")
    }).catch((e)=>{
        // console.log(e)
        message.error("Message Not Sent!")
    });
}


export const logIn = (email, password) =>{
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      window.location.reload()
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      message.error("Login failed")
    });
}

export const getAllMessages = () => {

return new Promise((resolve, reject)=>{
    getDocs(collection(db, "messages")).then((e)=>{

        let m = []
            e.docs.forEach((element, i)=>{
                m.push({...element.data(), key: `${i+1}`})
            })
        // console.log(m)
        resolve(m)

    }).catch((e)=>{

        reject(e)

    });

})

}


