
const girlsproducts = [
    {
      id: 1,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Girls/Girls+Hoody%2C+Fabrication-95%25+Cotton%2C+5%25+Viscose.jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Girls/Girls+Hoody%2C+Fabrication-99%25+Cotton%2C+1%25+Viscose+(Fleece+Inside).jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
  ]

  export default girlsproducts