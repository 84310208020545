import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import Products from './pages/products';
import Gallery from './pages/gallery';
import Recognition from './pages/recognition';
import About from './pages/about';
import Contact from './pages/contact';
import Admin from './pages/admin';
import ComingSoon from './pages/ComingSoon';

function App(page) {

  switch(page[1]){
    case "home": 
    return (
      <div className="App">
        <Home/>
      </div>
    );
    break;
    case "": 
    return (
      <div className="App">
       <Home/>
      </div>
    );
    
    break;
    case "about": 
    return (
      <div className="App">
        <About/>
      </div>
    );
    break;
    case "gallery": 
    return (
      <div className="App">
        <Gallery/>
      </div>
    );
    break;
    case "products": 
    return (
      <div className="App">
        <Products/>
      </div>
    );
   
    break;
    case "recognition": 
    return (
      <div className="App">
        <Recognition/>
      </div>
    );
    
    break;
    case "vr%20room": 
    return (
      <div className="App">
     <ComingSoon/>
      </div>
    );
    
    break;
    case "admin": 

    Admin()
    break;
    case "contact": 
    return (
      <div className="App">
        <Contact/>
      </div>
    );
    break;
    default: 
    return (
      <div className="App">
        <Home/>
      </div>
    );
    break;
  }


}

export default App;
