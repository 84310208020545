import ClientSlider from "../components/clientSlider"
import { Divider } from "antd"
import HomepageHero from "./homepageHero"
import GallerySlider from "../components/gallerySlider"
import HomePageNumberCards from "../components/homePageNumberCards"
import SolutionWeProvide from "../components/HomepageSolutions"
import ContactForm from "../components/contactForm"
import AimForExcellence from "../components/AimForEx"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import HomeMainCarousel from "../components/homeMainCarousel"


const Home  = () => {

    return(
        <div className="pt-12 md:pt-0 lg:pt-0">
        
        <p className="font-bold text-theme-red mt-6">.. Since 1993 ..</p>
        <p className="font-bold text-6xl text-theme-grey mt-8 ">LIFE TEXTILE PVT. LTD. </p>
        <p className="font-bold text-2xl text-gray-500 mt-4 ">100% EXPORT ORIENTED GARMENTS</p>
        
<div>
<div className="z-0 " >
<button onClick={()=>{window.location = "./products"}} className="rounded-full bg-theme-red py-4 px-10 mt-10 mb-4  z-10 transiction delay-100 duration-700 hover:bg-theme-green text-white font-bold"  >Explore Styles</button>
    </div>    
<div className="relative">
<div className="w-full   flex justify-center items-center">

  <div className=" relative ">
<center>
{/* <img src={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/bannerroundbg.png"}  className="opacity-0" /> */}

</center>

    <div className="absolute inset-0 flex justify-center items-center z-10">
 
<center>



</center>

    </div>
  </div>
</div>
</div>
</div>
<center>
<div style={{transform:"scale(0.8)"}}>
<HomeMainCarousel />
</div>
</center>

<HomePageNumberCards/>
<br></br>
<br></br>
<center>
    <div className="w-ful md:w-1/3"><Divider className="border-amber-500  ">OUR PARTNERS</Divider></div>
 

    </center>

        <ClientSlider/>
        




<HomepageHero/>
<br></br>
<SolutionWeProvide/>
<br></br>


<div className="bg-theme-green p-12 mt-0 md:mt-12">
  
<center>
  <GallerySlider/>

  
{/* <video src={cvid}   style={{height:`${window.innerHeight /2}px`}} className="rounded shadow-xl hover:blur-none hover:scale-125 transaction duration-500 delay-100 blur-sm" ></video> */}
</center>
</div>
{/* <SolutionWeProvide/> */}
<center>

<AimForExcellence/>


{/* <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 md:p-4  gap-0 p-0 w-full md:w-2/3">
  <div>
  <p className="font-bold text-amber-500 mt-16">.. TEXTILE NEWS ..</p>
  <p className="font-bold text-4xl text-black m-8">LATEST NEWS</p>

    <NewsCards/></div>
  <div> <p className="font-bold text-amber-500 mt-16">.. ASK QUESTION ..</p>
  <p className="font-bold text-4xl text-black m-8">GET EVERY ANSWERS </p>
  
  <Divider></Divider>
  <Collapse items={getItems(panelStyle)} bordered={false} style={{margin:"10px"}} />
  </div>
</div> */}
</center>
<ContactForm/>
        </div>
    )
}


export default Home