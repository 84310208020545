import React, { useEffect, useState } from 'react'
import SolutionGridContainer from './SolutionGrid/SolutionGridContainer'

const SolutionWeProvide = () => {
  let [ offsetY, setOffsetY] = useState(0);
  const handlerScroll = () => setOffsetY(window.pageYOffset - (window.pageYOffset / 1.2))
  
    useEffect(() => {
      window.addEventListener('scroll', handlerScroll)
  
      return () => window.removeEventListener('scroll', handlerScroll)
    }, [])
  return (
    <div style={{backgroundColor:"#ffffff"}} className='m-0 mb:m-20 lg:m-20'>
      
      <div style={{  width:"100%"}}>
        <h1 className='uppercase text-center mt-20  px-3 md:text-4xl text-3xl font-bold text-theme-green'>SOLUTIONS WE PROVIDE</h1>
        <p className=' text-center text-gray-500 text-sm my-3 md:px-32 px-5' >We’re an apparel manufacturer partner providing sustainable and organic one stop shop solutions for all things apparel production.Description for this block. Use this space for describing your block. Any text will do.</p>

      </div>
      
        <div style={{  width:"100%", margin:"auto"}}  className="grid grid-cols-1 md:grid-cols-4 md:px-20">
          
            <SolutionGridContainer  img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/thread.jpg"} title="QUALITY" subtitle="Product Quality Manegement" text="We can start with a sketch, a picture, or a verbal idea. We provide fashion design, sourcing, pattern making, and sample making." btn="Know More" link="/about"/>
             <SolutionGridContainer  img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/design.jpg"} title="DESIGN STUDIO" subtitle="Product Development Studio" text="We can start with a sketch, a picture, or a verbal idea. We provide fashion design, sourcing, pattern making, and sample making." btn="Know More" link="/about"/>
            <SolutionGridContainer  img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/manufacture.jpg"} title="Manufacturing" subtitle="We Help You Grow Your Fashion Brand" text="Our apparel manufacturing system will help you stop worrying about production, so you can focus on growing your brand." btn="Know More" link="/about"/>
            <SolutionGridContainer   img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/textile1.jpg"} title="WASHING" subtitle="We Help You Grow Your Fashion Brand" text="Loreumm ipsum is a phase meaning that this part of the website does not have any specific given content, so this is nothing." btn="Know More" link="/about"/>
      
        </div>
    </div>
  )
}

export default SolutionWeProvide