import React from 'react'
import RecogAdapter from './RecogAdapter/RecogAdapter.js'

const Recognition = () => {
  return (
   <div className='pt-32 md:pt-0 lg:pt-0'>
       <h1 className="sm:mx-auto  sm:w-10/12 md:w-2/3 font-black text-theme-green  text-4xl  sm:text-5xl md:text-6xl lg:w-auto  xl:text-6xl  ">
    
      <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-theme-green to-theme-red ">
        Recognition
      </span>
      
    </h1>
     <div className='grid md:grid-cols-4 grid-cols-1 py-10 md:px-10 px-3' >
        <RecogAdapter img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/accord.webp"} title="Accord"/>
        <RecogAdapter img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/wrap.webp"} title="Wrap"/>
        <RecogAdapter img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/recycle.webp"} title="ISO"/>
        <RecogAdapter img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/bsci.webp"} title="bsci"/>
        <RecogAdapter img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/oeko.webp"} title="oeko-tex"/>
        <RecogAdapter img={"https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/organi.webp"} title="gots"/>
    </div>
   </div>
  )
}

export default Recognition