
const boysproducts = [
    {
      id: 1,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Hoody%2C+Fabrication-60%25+Cotton%2C+40%25+Polyster.jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Long+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 3,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton+(AOP)_1.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id:4,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton+(AOP)_2.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_1.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_10.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_2.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_3.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_4.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_5.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_6.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_7.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_8.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton_9.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Cotton.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-100%25+Polyster.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-60%25+Cotton+40%25+Polyster.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-95%25+Cotton+5%25+Viscose_1.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Boys/Boys+Short+Sleeve+T-Shirt%2C+Fabrication-95%25+Cotton+5%25+Viscose.jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
  ]

  export default boysproducts