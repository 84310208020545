
  const kidsproducts = [
    {
      id: 1,
      name: 'Earthen Bottle',
      href: '#',
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Kids/Kids+T-Shirt+%26+Bottom+Set%2C+Fabrication-100%25+Cotton+(T-Shit)+%26+100%25+Coton(Bottom).jpg',
      imageAlt: 'Tall slender porcelain bottle with natural clay textured body and cork stopper.',
    },
    {
      id: 2,
      name: 'Nomad Tumbler',
      href: '#',
   
      imageSrc: 'https://s3.ap-south-1.amazonaws.com/lifetextilebd.com/Products/Kids/Kids+Tank+Top+%26+Bottom+Set%2C+Fabrication-95%25+Cotton+5%25+Viscose+(Top)+%26+100%25+Cotton+(Bottom).jpg',
      imageAlt: 'Olive drab green insulated bottle with flared screw lid and flat top.',
    },
  ]

  export default kidsproducts